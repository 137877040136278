import React from "react";
import styles from "./button.module.css";
import { textButton } from '../../../texts/ru';

export const Button = ({
  kind = "form",
  text = "",
  textColor = "white",
  isCell = false,
  img,
  extraClass = "",
  ...rest
}) => {
  return (
    <button
      className={`text text_type_${
        isCell ? "btn-cart" : "medium-600"
      } text_color_${textColor} ${styles.button} ${styles[kind]} ${extraClass}`}
      {...rest}
    >
      {img ? <img src={img} alt={textButton.btnAlt} /> : text}
    </button>
  );
};

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import styles from "./app.module.css";
import { NotFoundPage } from "../../pages/not-found-page/not-found-page";
import { LoyaltyPage } from "../../pages/loyalty-page/loyalty-page";
import { PartnershipPage } from "../../pages/partnership-page/partnership-page";
import { GoodsCardPage } from "../../pages/goods-card-page/goods-card-page";
import { CartPage } from "../../pages/cart-page/cart-page";
import { PersonalAreaPage } from "../../pages/personal-area-page/personal-area-page";
import { MainPage } from "../../pages/main-page/main-page";
import { AssortmentPage } from "../../pages/assortment-page/assortment-page";
import { StartPage } from "../../pages/start-page/start-page";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { PrivacyPolicyPage } from "../../pages/privacy-policy-page/privacy-policy-page";
import { UserContext, CartContext } from "../../utils/context";
import {
  getFilterUnits,
  getShops,
  getUserData,
  getCart,
  refreshToken,
} from "../../utils/api";
import { ErrorBoundary } from "../error-boundary/error-boundary";
import GoogleAnalytics from "../google-analytics/google-analytics";
import { sendMetrik } from "../../utils/send-metric";
import { getElementForMetric } from "../../utils/get-metric-element";

function App() {
  const [userState, setUserState] = React.useState({});
  const [cartState, setCartState] = React.useState([]);
  const [currentShop, setCurrentShop] = React.useState({});
  const [shops, setShops] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState("1");
  const [unitsArr, setUnitsArr] = React.useState([]);
  const [filterQuery, setFilterQuery] = React.useState([]);
  const [isAdultPage, setIsAdultPage] = React.useState(
    !!localStorage.getItem("adult")
  );

  const handleAdultClose = () => setIsAdultPage(true);

  React.useEffect(() => {
    Promise.all([getShops(), getFilterUnits()]).then((res) => {
      setShops(res[0]);
      let lastShop = JSON.parse(localStorage.getItem("currentShop"));
      if (Array.isArray(lastShop)) {
        lastShop = null;
      }
      setCurrentShop(lastShop ?? res[0][0]);
      !lastShop &&
        localStorage.setItem("currentShop", JSON.stringify(res[0][0]));
      setUnitsArr(res[1]);
    });
    return () => {
      setShops([]);
      setCurrentShop({});
      setUnitsArr([]);
    };
  }, []);

  React.useEffect(() => {
    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");
    if (token) {
      Promise.all([getUserData(), getCart()]).then((res) => {
        if (res[0] && res[0].phone) {
          setUserState(res[0]);
          setCartState(res[1]);
        } else {
          refreshToken().then((res) => {
            Promise.all([getUserData(res.access), getCart(res.access)]).then(
              (res) => {
                if (res[0] && res[0].phone) {
                  setUserState(res[0]);
                  setCartState(res[1]);
                }
              }
            );
          });
        }
      });
    }
    return () => {
      setUserState({});
      setCartState([]);
    };
  }, []);

  const onChangeShop = (e) => {
    const targetShop = shops.find((x) => x.id === +e.target.value);
    setCurrentShop(targetShop);
    localStorage.setItem("currentShop", JSON.stringify(targetShop));
  };

  return (
    <div
      className={styles.app}
      onClick={(e) =>
        sendMetrik("click", window.location.pathname, getElementForMetric(e))
      }
    >
      <UserContext.Provider value={[userState, setUserState]}>
        <CartContext.Provider value={[cartState, setCartState]}>
          <BrowserRouter>
            <ErrorBoundary>
              <Header
                currentShop={currentShop}
                shops={shops}
                onChangeShop={onChangeShop}
              />
              <div className={styles.content}>
                <Switch>
                  <Route path="/main">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/" exact>
                    <MainPage
                      shops={shops}
                      currentShop={currentShop}
                      onChangeShop={onChangeShop}
                      unitsArr={unitsArr}
                    />
                  </Route>
                  <Route exact path="/assortment">
                    <AssortmentPage
                      shops={shops}
                      currentShop={currentShop}
                      onChangeShop={onChangeShop}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      unitsArr={unitsArr}
                      filterQuery={filterQuery}
                      setFilterQuery={setFilterQuery}
                    />
                  </Route>
                  <Route path="/assortment/:id">
                    <GoodsCardPage
                      setFilterQuery={setFilterQuery}
                      unitsArr={unitsArr}
                    />
                  </Route>
                  <Route path="/cart">
                    <CartPage currentShop={currentShop} unitsArr={unitsArr} />
                  </Route>
                  <Route path="/pa">
                    <PersonalAreaPage />
                  </Route>
                  <Route path="/privacy">
                    <PrivacyPolicyPage />
                  </Route>
                  <Route path="/partners">
                    <PartnershipPage />
                  </Route>
                  <Route path="/loyalty">
                    <LoyaltyPage />
                  </Route>
                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </div>
              <Footer />
              {!isAdultPage && <StartPage handleClose={handleAdultClose} />}
            </ErrorBoundary>
            <GoogleAnalytics />
          </BrowserRouter>
        </CartContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;

export const getElementForMetric = ({ target }) => {
  let elementIdentifier = "";

  if (target.id) elementIdentifier = `id: ${target.id}; `;

  const tagName = target.tagName.toLowerCase();

  elementIdentifier += `tag: ${tagName}; `;

  if (tagName === "img") {
    elementIdentifier += `Ссылка: ${target.src}`;
  } else if (tagName === "a") {
    elementIdentifier += `Ссылка: ${target.href}; Содержимое: ${target.innerText}`;
  } else if (
    tagName === "p" ||
    tagName.startsWith("h") ||
    tagName === "button"
  ) {
    elementIdentifier += `Содержимое: ${target.innerText}`;
  } else {
    elementIdentifier += `Клик по пустому полю`;
  }

  return elementIdentifier;
};

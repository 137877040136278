import React from "react";
import styles from "./error-boundary.module.css";
import { textErrorBoundary } from "../../texts/ru";
import { Button } from "../ui/button/button";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // с помощью этого метода меняем стейт компонента при возникновении ошибки:
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // с помощью этого метода логируем информацию об ошибке:
  componentDidCatch(error, info) {
    console.log("Возникла ошибка!", error, info);
  }

  onClick() {
    window.location.href = "/";
  }

  render() {
    if (this.state.hasError) {
      // если возникла ошибка, сообщаем об этом пользователю в специальном компоненте:
      return (
        <section className={styles.error_box}>
          <h1 class="text text_type_h2 mb-8">{textErrorBoundary.title}</h1>
          <p className={`text text_type_large  ${styles.text}`}>
            {textErrorBoundary.text}
            <a
              className={`text text_type_large text_color_primary`}
              href="tel:+7(926)040-89-07"
            >
              {textErrorBoundary.support}
            </a>
          </p>

          <Button
            extraClass="mt-40"
            text={textErrorBoundary.returnBtn}
            onClick={this.onClick}
          />
        </section>
      );
    }
    // если всё работает штатно, рендерим дочерние компоненты
    return this.props.children;
  }
}

export const sendMetrik = (action, page, element) => {
  const values = {};
  if (typeof page !== "undefined") {
    values["event_page"] = page;
  }
  if (typeof element !== "undefined") {
    values["event_element"] = element;
  }
  if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
    window.gtag("event", action, values);
  } else {
    // на случай, если локально статистика отключена
    console.log("event", action, values);
  }
};
